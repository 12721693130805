.container {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 10px;

  .bg {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    filter: blur(75px);
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      height: 70px;
      width: 70px;
      border-radius: 10px;
    }

    .songDetails {
      margin-left: 15px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;

      .name {
        text-decoration: none;
        color: #ffffff;
        font-size: 20px;
        width: 100%;

        &:hover {
          text-decoration: underline;
        }
      }

      .artists {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .artist {
          font-size: 16px;
          color: #c0c0c0;
          text-decoration: none;
          margin: 0px 4px;

          &:nth-child(1) {
            margin-left: 0px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
