.container {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #181818;

  .brand {
    height: 50px;
    margin: 0px 15px;
  }

  .notice {
    margin: 0px 15px;
    position: relative;
    color: #e0dfdf;
    margin-bottom: 5px;
    font-style: italic;

    &::before {
      content: "";
      position: absolute;
      height: 25px;
      width: 1px;
      left: -16px;
      top: 2px;
      font-style: normal;
      background-color: #757575;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      100deg,
      #1db954,
      #00ae84,
      #00a0ae,
      #008dc9,
      #0077cd,
      #0f5cb9
    );
  }
}

@keyframes visibleAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hiddenAnim {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.visible {
  opacity: 0;
  animation: visibleAnim 200ms forwards !important;
}

.hidden {
  opacity: 1;
  animation: hiddenAnim 200ms forwards !important;
}
