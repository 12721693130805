@keyframes innerTextAnim {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.hero {
  min-height: 450px;
  height: 70vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  background-image: url("../images/curve.svg"),
    linear-gradient(30deg, #1db954, #00ae84, #00a0ae, #008dc9, #0077cd, #0f5cb9);
  background-repeat: no-repeat;
  background-position: bottom;

  .innerText {
    position: relative;
    top: 320px;
    text-align: center;
    font-size: 17px;
    color: #c7c7c7;

    * {
      opacity: 0;
      animation: innerTextAnim 500ms forwards;
    }
  }

  @for $i from 1 through 2 {
    .innerText *:nth-child(#{$i}n) {
      animation-delay: #{$i * 50}ms;
    }
  }
}

.main {
  height: fit-content;
  margin-bottom: 85px;
}

.section {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(even) {
    * {
      flex-direction: row-reverse;
    }
  }

  .innerSection {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sectionText {
      max-width: 500px;
    }

    img {
      height: 384px;
      width: 384px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .hero {
    background-image: linear-gradient(
      30deg,
      #1db954,
      #00ae84,
      #00a0ae,
      #008dc9,
      #0077cd,
      #0f5cb9
    );
  }
}
