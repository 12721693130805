.container {
  height: 370px;
  width: 300px;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  color: #ffffff;

  .bg {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
    filter: blur(75px);
  }

  .content {
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;

    p {
      height: 50px;
      width: 100%;
      font-size: 30px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 10px;
      text-align: center;
    }

    img {
      height: 300px;
      width: 300px;
      padding: 10px;
      border-radius: 20px;
    }
  }
}
