.container {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
}

.loader {
  height: 40px;
  width: 8px;
  border-radius: 4px;
  background-color: #25d125;
  position: relative;
  animation: loaderAnim 0.6s infinite;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &::before,
  &::after {
    content: "";
    height: 40px;
    width: 8px;
    border-radius: 4px;
    background-color: #25d125;
    position: absolute;
    animation: loaderAnim 0.6s infinite;
  }

  &::after {
    left: 20px;
    animation-delay: 0.4s;
  }

  &::before {
    left: -20px;
    animation-delay: 0.2s;
  }
}

@keyframes loaderAnim {
  0% {
    height: 40px;
  }

  50% {
    height: 8px;
  }

  100% {
    height: 40px;
  }
}
