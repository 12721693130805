.container {
  width: 100%;
  height: 64px;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    padding: 0px 15px;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;

      .brand img {
        height: 50px !important;
        margin-top: 8px;
      }

      .links {
        margin-left: 10px;
      }

      .links * {
        text-decoration: none;
        color: white;
        transition: color 250ms ease;
        margin: 0px 7px;

        &:hover {
          color: #25d125;
        }
      }
    }

    .toggleIcon {
      display: none;
    }
  }
}

@media only screen and (max-width: 900px) {
  .container {
    .left {
      width: 100%;
      margin: 0;

      .brand {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        img {
          margin: 0px !important;
        }

        .toggleIcon {
          color: white;
          display: block;
          font-size: 30px;
        }
      }

      .links {
        height: calc(100% - 63px);
        font-size: 20px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        display: none;
      }
    }

    .right {
      display: none;
    }
  }

  .expanded {
    height: 100vh;
    width: 100vw;
    align-items: flex-start;
    background-color: #181818;
    position: fixed;
    z-index: 100;

    .inner {
      height: 100vh;
      width: 100vw;
      flex-direction: column;

      .left {
        width: 100%;
        height: 50%;
        flex-direction: column;

        .links {
          display: flex;

          * {
            margin: 7px 0px;
          }
        }
      }

      .right {
        height: 50%;
        width: 100%;
        margin: 10px 0px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
    }
  }
}
