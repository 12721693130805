.container {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0px;
  flex-direction: column;

  .name {
    color: white;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 20px;
    margin: 10px 0px;
  }

  .bar {
    width: 100%;
    height: 100%;
    background-color: #25d125;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    transition: all 450ms ease;
    padding: 5px;
  }
}
